import { useEffect, useState } from 'react';

export const usePeriodicRender = (ms: number = 1000) => {
    const [token, setToken] = useState<number>(1);

    useEffect(() => {
        setTimeout(() => requestAnimationFrame(() => {
            setToken(token + 1);
        }), 1000);
    });

    return `${token}`;
}