import React, { FC, ReactNode } from 'react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import {
  FullHeight,
  Icon,
  PinWithMenu,
  AddButton,  
  COLOR_BASE,
  COLOR_ACCENT,
  COLOR_DARK,
  COLOR_WHITE,
  Logo,
} from 'ui';
import { usePinData, usePeriodicRender } from 'data';

const Wrapper = styled(FullHeight)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  background-color: ${COLOR_WHITE};
  border-bottom: .25rem solid ${COLOR_DARK};
  border-radius: 1rem;
  width: 400px;
  max-width: 95vw;
  max-height: 90vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem 1rem 1rem;
  position: relative;
`;

const logoPinAppear = keyframes`
  from {
    margin-top: 4rem;
    height: 0;
  }
  to {
    margin-top: 0;
    height: 4rem;
  }
`;
const Headline = styled.h1`
  font-family: "Grand Hotel";
  text-align: center;
  color: ${COLOR_ACCENT};
  /* text-shadow: 0 2px 2px ${COLOR_BASE}; */
  filter: drop-shadow(0 2px 2px ${COLOR_BASE}) drop-shadow(0 2px 2px ${COLOR_BASE});
  font-size: 4rem;
  margin: 1.5rem 0 1.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  > svg {
    fill: ${COLOR_ACCENT};
    transform: translateY(.5rem);
    margin-left: .25rem;
    animation: ${logoPinAppear} .8s ease;
  }
`;

const pinAppear = keyframes`
  from {
    font-size: 1px;
  }
  to {
    font-size: 5rem;
  }
`;

const degRot = '5deg';
const pinAnim = keyframes`
    from {
        transform: rotate(${degRot}) scale(1);
    }
    3% {
        transform: rotate(-${degRot});
    }
    6% {
        transform: rotate(${degRot});
    }
    9% {
        transform: rotate(-${degRot}) scale(1.2);
    }
    12% {
        transform: rotate(${degRot});
    }
    14% {
        transform: rotate(-${degRot});
    }
    16%, to {
        transform: rotate(${degRot}) scale(1);
    }
`;
const AnimatedPin = styled(Icon)`
  animation: ${pinAnim} 5s ease infinite, ${pinAppear} 1s ease;
  margin: 0 auto;
  font-size: 5rem;
  > svg {
    fill: ${COLOR_BASE};
  }
`;
const Scrollable = styled.div<{
  children: ReactNode | ReactNode[];
}>`
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
  > :first-of-type {
    border-radius: 1rem 1rem 0 0;
  }
`;
const Legal = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  color: ${COLOR_DARK}AA;
  font-size: .8rem;
  margin: .2rem;
  a {
    color: inherit;
    text-decoration: none;
    margin-right: .2rem;
    :hover {
      color: ${COLOR_WHITE};
    }
  }
`;

const App: FC = () => {
  const {
    pending,
    mapPins,
    pinCount,
    addPin,
    removePin,
    saving,
    pinNow,
    editPin,
  } = usePinData();
  const token = usePeriodicRender();

  const noPins = pinCount === 0;

  return (
    <Wrapper>
      <Legal>
        <a href="http://cande.app" target="_blank" rel="noreferrer">
          Copyright &copy; {new Date().getFullYear()} C&amp;E Apps
        </a>
        inspired by Ryan
      </Legal>
      <Global styles={css`
          html, body {
              margin: 0;
              padding: 0;
              background-color: ${COLOR_BASE};
              color: ${COLOR_DARK};
              overscroll-behavior: contain;
              font-family: "PT Serif", serif;
          }
      `} />
      <Content onClick={!pending && noPins ? addPin : undefined}>
        <Headline>
          WhenPin
          {!noPins && (
            <Logo height="4rem" width="4rem" />
          )}
        </Headline>
        {!pending && <>
          {noPins && (
            <AnimatedPin>
              <Logo height="5rem" />
            </AnimatedPin>
          )}
          <Scrollable>
            {mapPins(pin =>
              <PinWithMenu
                key={pin.id}
                pin={pin}
                rerenderToken={token}
                pinNow={pinNow.bind(undefined, pin.id)}
                removePin={removePin.bind(undefined, pin.id)}
                editPin={editPin.bind(undefined, pin.id)}
                menuDisabled={saving}
              />
            )}
          </Scrollable>
          {!noPins && (
            <AddButton onClick={addPin} />
          )}
        </>}
      </Content>
    </Wrapper>
  );
}

export default App;
