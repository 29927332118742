import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { get, set } from 'idb-keyval';
import { v4 as uuidv4 } from 'uuid';
import { PinInstanceRecord, UUID, PinInstance, PinData } from "./types";

const useSavePins = (saveKey: string = 'pindata') => {
    const [saving, setSaving] = useState<boolean>(false);
    const save = useCallback(async (pinsToSave: PinInstanceRecord) => {
        setSaving(true);
        await set(saveKey, pinsToSave)
        setSaving(false);
    }, [saveKey]);

    return { saving, save };
};

export const usePinData = (saveKey: string = 'pindata') => {
    const [pins, setPins] = useState<PinInstanceRecord>({});
    const [pending, setPending] = useState<boolean>(true);
    const pinCount = useMemo(() => Object.keys(pins).length, [pins]);
    const { saving, save } = useSavePins(saveKey);

    const editPin = useCallback((
        id: UUID,
        newData: Partial<PinData>
    ) => {
        setPins(p => {
            const {
                [id]: preEditPin,
                ...uneditedPins
            } = p;

            const newPins = {
                [id]: {
                    ...preEditPin,
                    history: [
                        { ...preEditPin.history[0], ...newData },
                        ...preEditPin.history
                    ]
                },
                ...uneditedPins,
            };

            save(newPins);

            return newPins;
        });
    }, [save]);

    useEffect(() => {
        (async () => {
            try {
                const loadedPins = await get<PinInstanceRecord>(saveKey);
                if (loadedPins) {
                    setPins(p => ({ ...p, ...loadedPins }));
                }
            } finally {
                setPending(false);
            }
        })();
    }, [saveKey]);

    return {
        saving,
        pending,
        pins,
        pinCount,
        mapPins: (callback: ((pin: PinInstance, index: number) => ReactNode)) => Object.keys(pins)
            .sort((a, b) => pins[a].order - pins[b].order)
            .map((pinId, i) => callback(pins[pinId], i)),
        removePin: useCallback((id: UUID) => {
            setPins(p => {
                const { [id]: _, ...newPins } = p;
                save(newPins);
                return newPins;
            });
        }, [save]),
        addPin: useCallback(() => {
            const timestamp = Date.now();
            const pin: PinInstance = {
                id: uuidv4(),
                history: [ { timestamp } ],
                order: timestamp,
            };
            setPins(p => {
                const newPins = {
                    ...p,
                    [pin.id]: pin,
                };
                save(newPins);
                return newPins;
            });
            return pin.id;
        }, [save]),
        pinNow: useCallback((id: UUID) =>
            editPin(id, { timestamp: Date.now() }),
            [editPin]
        ),
        editPin,
    }
};
