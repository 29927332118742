import React, { ComponentProps, FC, useCallback, useState } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import prettyMs from 'pretty-ms';
import { PinData, PinInstance } from "data";
import { PinMenu } from "./PinMenu";
import { COLOR_DARK } from "./colors";
import { Flex } from "./primitives";

const pinAppear = keyframes`
  from {
    max-height: 0;
    opacity: .25;
  }
  to {
    max-height: 200px;
    opacity: 1;
  }
`;
const Wrap = styled.div`
    background-color: rgba(0, 0, 0, 0.1);
    padding: .5rem 1rem;
    box-sizing: border-box;
    animation: ${pinAppear} .5s ease;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-items: center;
`;
const PinDate = styled(Flex)`
    font-size: 0.8rem;
    color: ${COLOR_DARK}99;
`;
const CountUp = styled.div`
    font-size: 1.2rem;
    color: ${COLOR_DARK};
    white-space: nowrap;
`;
const Label = styled.span`
    color: ${COLOR_DARK}BB;
    font-size: 1rem;
    margin-left: .5rem;
    text-align: right;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Pin: FC<{
    pin: PinInstance;
    rerenderToken: string;
    onClick?: () => void;
}> = ({
    pin,
    onClick,
    rerenderToken,
}) => {
    const current = pin.history[0];
    const date = new Date(current.timestamp);
    const msSince = Date.now() - current.timestamp;

    return <>
        <Wrap
            data-token={rerenderToken}
            onClick={onClick}
        >
            <CountUp>
                {msSince < 1000 ? 'Just now' : prettyMs(msSince, {
                    // verbose: true,
                    secondsDecimalDigits: 0,
                })}
            </CountUp>
            <Flex
                data-flex
                data-direction="column"
                data-align="flex-end"
            >
                {current.label && (
                    <Label>{current.label}</Label>
                )}
                <PinDate>
                    Since {date.toLocaleString()}
                </PinDate>
            </Flex>
        </Wrap>
    </>;
};

export const PinWithMenu: FC<{
    pinNow: () => void;
    removePin: () => void;
    editPin: (data: Partial<PinData>) => void;
    menuDisabled: boolean;
} & ComponentProps<typeof Pin>> = ({
    pinNow,
    removePin,
    editPin,
    menuDisabled,
    ...props
}) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    return <>
        <Pin
            {...props}
            onClick={useCallback(() => setMenuOpen(o => !o), [])}
        />
        <PinMenu
            pin={props.pin}
            rerenderToken={props.rerenderToken}
            open={menuOpen}
            onClose={useCallback(() => setMenuOpen(false), [])}
            pinNow={pinNow}
            editPin={editPin}
            removePin={removePin}
            disabled={menuDisabled}
        />
    </>;
}