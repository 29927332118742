import React, { FC, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import prettyMs from 'pretty-ms';
import { PinData, PinInstance } from 'data';
import { COLOR_ACCENT, COLOR_BASE, COLOR_DARK, COLOR_WHITE } from './colors';
import { Pin } from './Pin';
import { AddButton } from './AddButton';
import { Logo } from './Logo';
import { Icon } from './Icon';

const Wrap = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 1rem;
    gap: .2rem;
`;

const backdropAppear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Backdrop = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${COLOR_DARK}CC;
    border-radius: .95rem .95rem .8rem .8rem;
    animation: ${backdropAppear} .25s ease;
`;

const menuAppear = keyframes`
  from {
    opacity: .25;
  }
  to {
    opacity: 1;
  }
`;
const Menu = styled.div`
    display: flex;
    flex-direction: column;
    animation: ${menuAppear} .8s ease;
    z-index: 100;
    align-self: stretch;
    margin: 0 1rem;
    position: relative;
`;

const CloseButton = styled.button`
    font-family: 'OutlineEmoji';
    position: absolute;
    bottom: 105%;
    right: 0;
    border: none;
    background-color: ${COLOR_ACCENT}99;
    border-radius: 50%;
    color: ${COLOR_DARK};
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
`;
CloseButton.defaultProps = {
    children: '❌'
};
const DeleteButton = styled(CloseButton)`
    right: auto;
    left: 0;
`;
DeleteButton.defaultProps = {
    children: '🗑'
};
const StyledAddButton = styled(AddButton)`
    > svg {
        transform: translateY(.35rem);
    }
`;
const LightBackdrop = styled.div`
    background-color: ${COLOR_WHITE};
    margin-bottom: 5px;
`;

const historyAppear = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 100px;
  }
`;
const HistoryWrap = styled.div`
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: .75rem .25rem .75rem .25rem;
    white-space: nowrap;
    animation: ${historyAppear} 1s ease;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
`;
const HistoryItem = styled.div`
    background-color: ${COLOR_DARK}22;
    border-radius: .5rem;
    padding: .5rem;
    font-size: .8rem;
    color: ${COLOR_DARK}BB;
    font-weight: bold;
`;
const TimeGap = styled.div`
    padding: .5rem;
    ::before, ::after {
        content: '—';
        padding: 0 .25rem;
        font-weight: bold;
        color: ${COLOR_BASE};
        opacity: .2
    }
`;

export const PinMenu: FC<{
    open: boolean;
    pin: PinInstance;
    onClose: () => void;
    pinNow: () => void;
    removePin: () => void;
    editPin: (data: Partial<PinData>) => void;
    rerenderToken: string;
    disabled: boolean;
}> = ({
    pin,
    open,
    onClose,
    pinNow,
    removePin,
    editPin,
    rerenderToken,
    disabled,
}) => {
    const editLabel = useCallback(() => {
        const answer = window.prompt(
            `Enter label for pin at ${new Date(pin.history[0].timestamp).toLocaleString()}`,
            pin.history[0].label
        );
        if (answer !== null) {
            editPin({ label: answer || '' });
        }
    }, [pin, editPin]);
    const pinWithDefaultLabel = useMemo(() => ({
        ...pin,
        history: [
            {
                ...pin.history[0],
                label: pin.history[0].label || 'Add label'
            },
            ...pin.history.slice(1),
        ]
    }), [pin]);
    const remove = useCallback(() => {
        if (window.confirm("Delete pin, are you sure? You can't undo this."))
            removePin();
    }, [removePin]);

    return !open ? null : <Wrap>
        <Backdrop onClick={onClose} />
        <Menu>
            <CloseButton onClick={onClose} />
            <DeleteButton onClick={remove} disabled={disabled} />
            <LightBackdrop>
                <Pin
                    pin={pinWithDefaultLabel}
                    rerenderToken={rerenderToken}
                    onClick={disabled ? undefined : editLabel}
                />
                {pin.history.length > 1 && (
                    <HistoryWrap>
                        {pin.history.map((data, i) => {
                            const prettyDate = new Date(data.timestamp).toLocaleString();
                            const previousLabel = i < pin.history.length - 1 ? pin.history[i + 1].label : undefined;
                            const hasNewLabel = previousLabel !== data.label;
                            const historyTimeGap = pin.history[i+1] ? data.timestamp - pin.history[i+1].timestamp : false;
                            return <React.Fragment 
                                key={data.timestamp + (data.label || '')}
                            >
                                <HistoryItem
                                    onClick={() => window.alert(hasNewLabel ? (
                                        `At ${prettyDate}, label changed from "${previousLabel || ''}" to "${data.label}"`
                                    ) : (
                                        `Time pinned at ${prettyDate}`
                                    ))}
                                >
                                    {hasNewLabel ? (
                                        <Icon>🏷</Icon>
                                    ) : (
                                        <Icon>🕛</Icon>
                                    )}
                                </HistoryItem>
                                {historyTimeGap ? (
                                    <TimeGap>
                                        {prettyMs(historyTimeGap, {
                                            secondsDecimalDigits: 0,
                                        })}
                                    </TimeGap>
                                ) : null}
                            </React.Fragment>;
                        })}
                    </HistoryWrap>
                )}
            </LightBackdrop>
            <StyledAddButton
                onClick={pinNow}
                disabled={disabled}
                data-noanim
            >
                Pin to now<Logo height="2rem" width="2rem" />
            </StyledAddButton>
        </Menu>
    </Wrap>;
};
