import styled from '@emotion/styled';

export interface FlexProps {
    ['data-direction']?: 'row' | 'column';
    ['data-flex']?: true | number;
    ['data-gap']?: string;
    ['data-justify']?: string;
    ['data-align']?: string;
};

export const selectFlexStyles = (props: FlexProps): string => {
    const options = [];

    if (props['data-direction']) {
        options.push(`flex-direction: ${props['data-direction']};`);
    }
    if (props['data-flex']) {
        options.push(`flex: ${props['data-flex'] === true ? '1' : props['data-flex']};`);
    }
    if (props['data-gap']) {
        options.push(`gap: ${props['data-gap']};`);
    }
    if (props['data-justify']) {
        options.push(`justify-content: ${props['data-justify']};`);
    }
    if (props['data-align']) {
        options.push(`align-items: ${props['data-align']};`);
    }

    return options.join(' ');
}

export const Flex = styled.div<FlexProps>`
    display: flex;
    ${selectFlexStyles}
`;
